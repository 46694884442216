import React from 'react';
import {
  MediaGallerySkeleton,
  HEIGHT_DESKTOP,
  HEIGHT_MOBILE,
} from '@hotelplan/components.common.media-gallery';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import RecommendationListSkeleton from 'components/domain/recommendation-list/RecommendationList.skeleton';

const HomeDynamicComponentsSkeleton = () => {
  const { mobile } = useDeviceType();

  return (
    <>
      <RecommendationListSkeleton hasTitle={true} />
      <RecommendationListSkeleton hasTitle={true} />
      <MediaGallerySkeleton
        uniqueKey="mediaGallerySkeleton"
        height={mobile ? HEIGHT_MOBILE : HEIGHT_DESKTOP}
      />
    </>
  );
};

export default HomeDynamicComponentsSkeleton;
