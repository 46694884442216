import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { HolidayFinderLandingPageFragmentDoc } from './HolidayFinderLandingPageFragment.generated';
import { HolidayFinderPageFragmentDoc } from './HolidayFinderPageFragment.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetHolidayFinderStaticComponentsQueryVariables = Types.Exact<{
  context: Types.RequestContextInput;
}>;

export type GetHolidayFinderStaticComponentsQuery = {
  __typename?: 'Query';
  holidayFinder: {
    __typename?: 'HolidayFinderPageContext';
    landingPage: {
      __typename?: 'HolidayFinderLandingPage';
      children: boolean;
      hero: {
        __typename?: 'Image';
        alt: string;
        desaturated?: boolean | null;
        resized: Array<{
          __typename?: 'ResizedImage';
          url: string;
          id: string;
        }>;
      };
      info: {
        __typename?: 'TextComponent';
        text: string;
        title: string;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      };
      travelPeriod: {
        __typename?: 'TravelPeriodComponent';
        type: Types.SearchPeriodType;
        exactPeriod: {
          __typename?: 'ExactTravelPeriod';
          departureDate: string;
          returnDate: string;
        };
        flexiblePeriod: {
          __typename?: 'FlexibleTravelPeriod';
          departureDate: string;
          maxDuration: number;
          minDuration: number;
          returnDate: string;
        };
      };
    };
    resultPage: {
      __typename?: 'HolidayFinderPage';
      info: {
        __typename?: 'TextComponent';
        text: string;
        title: string;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      };
    };
    votingPage: {
      __typename?: 'HolidayFinderPage';
      info: {
        __typename?: 'TextComponent';
        text: string;
        title: string;
        fontColor?: string | null;
        backgroundColor?: string | null;
        backgroundImage?: {
          __typename?: 'Image';
          alt: string;
          desaturated?: boolean | null;
          resized: Array<{
            __typename?: 'ResizedImage';
            url: string;
            id: string;
          }>;
        } | null;
      };
    };
  };
};

export const GetHolidayFinderStaticComponentsDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHolidayFinderStaticComponents' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NonNullType',
            type: {
              kind: 'NamedType',
              name: { kind: 'Name', value: 'RequestContextInput' },
            },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'holidayFinder' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'landingPage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: {
                          kind: 'Name',
                          value: 'holidayFinderLandingPage',
                        },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'resultPage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'holidayFinderPage' },
                      },
                    ],
                  },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'votingPage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'holidayFinderPage' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...HolidayFinderLandingPageFragmentDoc.definitions,
    ...HolidayFinderPageFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetHolidayFinderStaticComponentsQuery__
 *
 * To run a query within a React component, call `useGetHolidayFinderStaticComponentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHolidayFinderStaticComponentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHolidayFinderStaticComponentsQuery({
 *   variables: {
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetHolidayFinderStaticComponentsQuery(
  baseOptions: ApolloReactHooks.QueryHookOptions<
    GetHolidayFinderStaticComponentsQuery,
    GetHolidayFinderStaticComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHolidayFinderStaticComponentsQuery,
    GetHolidayFinderStaticComponentsQueryVariables
  >(GetHolidayFinderStaticComponentsDocument, options);
}
export function useGetHolidayFinderStaticComponentsLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHolidayFinderStaticComponentsQuery,
    GetHolidayFinderStaticComponentsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHolidayFinderStaticComponentsQuery,
    GetHolidayFinderStaticComponentsQueryVariables
  >(GetHolidayFinderStaticComponentsDocument, options);
}
export type GetHolidayFinderStaticComponentsQueryHookResult = ReturnType<
  typeof useGetHolidayFinderStaticComponentsQuery
>;
export type GetHolidayFinderStaticComponentsLazyQueryHookResult = ReturnType<
  typeof useGetHolidayFinderStaticComponentsLazyQuery
>;
export type GetHolidayFinderStaticComponentsQueryResult = Apollo.QueryResult<
  GetHolidayFinderStaticComponentsQuery,
  GetHolidayFinderStaticComponentsQueryVariables
>;
