import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { UnionSearchControlFragmentDoc } from 'graphql/searchControl/UnionSearchControl.generated';
import type * as Apollo from '@apollo/client';
import * as ApolloReactHooks from '@hotelplan/libs.context.req-ctx';
const defaultOptions = {} as const;
export type GetHomeSearchControlValuesQueryVariables = Types.Exact<{
  travelType?: Types.InputMaybe<Types.TravelType>;
  context?: Types.InputMaybe<Types.RequestContextInput>;
}>;

export type GetHomeSearchControlValuesQuery = {
  __typename?: 'Query';
  home: {
    __typename?: 'HomeContext';
    searchControl:
      | {
          __typename: 'FlightSearchControlComponent';
          travelType: Types.TravelType;
          flightType: Types.FlightType;
          travellers: {
            __typename?: 'Travellers';
            adults: number;
            childrenDobs?: Array<string> | null;
          };
          flightPartitions?: Array<{
            __typename?: 'FlightPartition';
            flightClass: Types.FlightClass;
            departureAirport?: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            } | null;
            arrivalAirport?: {
              __typename?: 'Airport';
              iata?: string | null;
              name?: string | null;
            } | null;
            travelPeriod: {
              __typename?: 'ExactTravelPeriod';
              returnDate: string;
              departureDate: string;
            };
          }> | null;
        }
      | {
          __typename: 'SearchControlComponent';
          travelType: Types.TravelType;
          rooms: number;
          period: {
            __typename?: 'TravelPeriodComponent';
            type: Types.SearchPeriodType;
            exactPeriod: {
              __typename?: 'ExactTravelPeriod';
              departureDate: string;
              returnDate: string;
            };
            flexiblePeriod: {
              __typename?: 'FlexibleTravelPeriod';
              departureDate: string;
              maxDuration: number;
              minDuration: number;
              returnDate: string;
            };
          };
          destinations?: Array<{
            __typename?: 'TravelDestination';
            id: string;
            name: string;
            type: Types.TravelDestinationType;
            mythosCode?: string | null;
            location?: {
              __typename?: 'GeoLocation';
              airport?: {
                __typename?: 'Airport';
                iata?: string | null;
                name?: string | null;
              } | null;
              continent?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              countryGroup?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              country?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              region?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              destination?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
              resort?: {
                __typename?: 'GeoObject';
                id: string;
                name: string;
                type: Types.GeoType;
              } | null;
            } | null;
          }> | null;
          travellers: {
            __typename?: 'Travellers';
            adults: number;
            childrenDobs?: Array<string> | null;
          };
          travellersDistribution?: {
            __typename?: 'TravellersDistribution';
            rooms: Array<{
              __typename?: 'RoomDistribution';
              adultsAmount: number;
              childrenIdx?: Array<number> | null;
            }>;
          } | null;
        };
  };
};

export const GetHomeSearchControlValuesDocument = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetHomeSearchControlValues' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'travelType' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'TravelType' },
          },
        },
        {
          kind: 'VariableDefinition',
          variable: {
            kind: 'Variable',
            name: { kind: 'Name', value: 'context' },
          },
          type: {
            kind: 'NamedType',
            name: { kind: 'Name', value: 'RequestContextInput' },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'home' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'deviceType' },
                value: { kind: 'EnumValue', value: 'DESKTOP' },
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'context' },
                value: {
                  kind: 'Variable',
                  name: { kind: 'Name', value: 'context' },
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'searchControl' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'travelType' },
                      value: {
                        kind: 'Variable',
                        name: { kind: 'Name', value: 'travelType' },
                      },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'FragmentSpread',
                        name: { kind: 'Name', value: 'UnionSearchControl' },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...UnionSearchControlFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;

/**
 * __useGetHomeSearchControlValuesQuery__
 *
 * To run a query within a React component, call `useGetHomeSearchControlValuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeSearchControlValuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeSearchControlValuesQuery({
 *   variables: {
 *      travelType: // value for 'travelType'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetHomeSearchControlValuesQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    GetHomeSearchControlValuesQuery,
    GetHomeSearchControlValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useQuery<
    GetHomeSearchControlValuesQuery,
    GetHomeSearchControlValuesQueryVariables
  >(GetHomeSearchControlValuesDocument, options);
}
export function useGetHomeSearchControlValuesLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    GetHomeSearchControlValuesQuery,
    GetHomeSearchControlValuesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return ApolloReactHooks.useLazyQuery<
    GetHomeSearchControlValuesQuery,
    GetHomeSearchControlValuesQueryVariables
  >(GetHomeSearchControlValuesDocument, options);
}
export type GetHomeSearchControlValuesQueryHookResult = ReturnType<
  typeof useGetHomeSearchControlValuesQuery
>;
export type GetHomeSearchControlValuesLazyQueryHookResult = ReturnType<
  typeof useGetHomeSearchControlValuesLazyQuery
>;
export type GetHomeSearchControlValuesQueryResult = Apollo.QueryResult<
  GetHomeSearchControlValuesQuery,
  GetHomeSearchControlValuesQueryVariables
>;
