import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { Form, IFormProps } from '@hotelplan/components.common.forms';
import BannerSearchFormSkeleton from 'components/domain/BannerSearchFormSkeleton.skeleton';
import { EmptyResultsModal } from 'components/domain/EmptyResults';
import { SearchControlInputsSkeleton } from 'components/domain/searchControl/search-control-skeleton';
import {
  mapSearchControlToCriteria,
  mapSearchControlValuesToFormState,
} from 'components/domain/searchControl/SearchControl.mappers';
import { ISearchControlState } from 'components/domain/searchControl/SearchControl.types';
import SearchControlFormResetButton from 'components/domain/searchControl/SearchControlFormResetButton';
import SearchControlTravelTypeContainer from 'components/domain/searchControl/SearchControlTravelTypeContainer';
import useSearchControlForm from 'components/domain/searchControl/useSearchControlForm';
import {
  GetHomeLinkDocument,
  GetHomeLinkQuery,
  GetHomeLinkQueryVariables,
} from 'graphql/home/GetHomeLink.generated';
import {
  GetHomeSearchControlValuesDocument,
  GetHomeSearchControlValuesQuery,
  GetHomeSearchControlValuesQueryVariables,
} from 'graphql/home/GetHomeSearchControlValues.generated';
import HomeSearchControlForm from './HomeSearchControlForm';

interface IHomeSearchFormProps {
  postSubmit?: (state: ISearchControlState) => void;
  className?: string;
  hasMoreVisibleItems?: boolean;
}
type TForm = { hasMoreVisibleItems: boolean };

const HomeForm = styled(Form)<TForm>(
  ({ theme }) => theme.forms.bannerForm,
  ({ hasMoreVisibleItems }) =>
    hasMoreVisibleItems
      ? {
          '.searchControlFormLayoutWrapper': {
            boxShadow: 'none',
            mb: 0,
          },
        }
      : null
) as React.FC<IFormProps<ISearchControlState> & TForm>;

const HomeSearchForm: React.FC<IHomeSearchFormProps> = ({
  postSubmit,
  className,
  hasMoreVisibleItems,
}) => {
  const [t] = useTranslation('results');

  const {
    initialValues,
    loading,
    defaultLoading,
    formApiRef,
    onChange,
    onReset,
    onApply,
    noOrlModal,
  } = useSearchControlForm<
    GetHomeSearchControlValuesQuery,
    GetHomeSearchControlValuesQueryVariables,
    GetHomeLinkQuery,
    GetHomeLinkQueryVariables
  >(
    {
      query: GetHomeSearchControlValuesDocument,
      variablesMapper: travelType => ({ travelType }),
      dataMapper: data =>
        mapSearchControlValuesToFormState(data.home.searchControl),
    },
    {
      query: GetHomeLinkDocument,
      variablesMapper: formState => ({
        homeSearchControlCriteriaInput: mapSearchControlToCriteria(formState),
      }),
      dataMapper: linkData => linkData.home.link,
    }
  );

  if (!initialValues || defaultLoading) return <BannerSearchFormSkeleton />;

  return (
    <>
      <EmptyResultsModal
        isModalShowed={noOrlModal.isModalShowed}
        onRenewSearch={noOrlModal.onRenewSearch}
        onClose={noOrlModal.onClose}
        title={t('noOrlModal.title')}
        description={t('noOrlModal.description', {
          productName: noOrlModal.productName || '',
        })}
        buttonContent={t('noOrlModal.buttonContent')}
      />
      <HomeForm
        hasMoreVisibleItems={hasMoreVisibleItems}
        className={className}
        formApiRef={formApiRef}
        initialValues={initialValues}
        onChange={onChange}
        onReset={onReset}
        onSubmit={nextFormState => {
          onApply(nextFormState);
          postSubmit?.(nextFormState);
        }}
      >
        <SearchControlFormResetButton />
        <SearchControlTravelTypeContainer>
          {loading ? (
            <SearchControlInputsSkeleton />
          ) : (
            <HomeSearchControlForm />
          )}
        </SearchControlTravelTypeContainer>
      </HomeForm>
    </>
  );
};

export default HomeSearchForm;
