import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { useGetHolidayFinderStaticComponentsQuery } from 'graphql/holiday-finder/GetHolidayFinderStaticComponents.generated';

const ContestTeaserWrapper = styled.div(({ theme: { media } }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'white',
  paddingBottom: '50px',
  borderRadius: '3px',
  [media.tablet]: {
    width: '970px',
    height: '360px',
    flexDirection: 'row',
    paddingBottom: '0px',
  },
}));

const ContestTeaserImageWrapper = styled.div(
  sx2CssThemeFn({
    flexBasis: ['250px', '570px'],
    flexShrink: 0,
    backgroundRepeat: 'no-repeat',
    backgroundColor: 'lightGreySecond',
    img: {
      height: ['250px', '100%'],
      width: '100%',
      objectFit: 'cover',
      display: 'block',
      borderRadius: '3px 0 0 3px',
    },
  })
);

const ContestTeaserContent = styled.div(
  sx2CssThemeFn({
    height: ['205px', '100%'],
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: ['20px', '40px'],
    border: '1px solid #E1E1E1',
    borderLeft: [null, 'none'],
    borderRadius: ['0 0 3px 3px', '0 3px 3px 0'],
  })
);

const ContestTeaserTextSection = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    display: [null, 'flex'],
    flexDirection: [null, 'column'],
    '.contest-teaser-title': FONT_SIZE.S,
    '.contest-teaser-text': {
      ...FONT_SIZE.LARGE,
      pt: [null, 2],
    },
  })
);

const HolidayFinderContestTeaser: React.FC = () => {
  const [t] = useTranslation('holidayfinder');
  const { push } = useRouter();

  const { data: staticData } = useGetHolidayFinderStaticComponentsQuery({});
  const teaserImage = staticData?.holidayFinder.landingPage.hero;

  return (
    <ContestTeaserWrapper>
      <ContestTeaserImageWrapper>
        {teaserImage ? <Image {...teaserImage} /> : null}
      </ContestTeaserImageWrapper>
      <ContestTeaserContent className="contest-teaser-content">
        <ContestTeaserTextSection>
          <span className="contest-teaser-title">
            {t('holidayfinder:contest.teaser.title')}
          </span>
          <span className="contest-teaser-text">
            {t('holidayfinder:contest.teaser.text')}
          </span>
        </ContestTeaserTextSection>

        <BsButton
          onClick={() => {
            push('/holidayfinder');
          }}
        >
          {t('holidayfinder:contest.teaser.button')}
        </BsButton>
      </ContestTeaserContent>
    </ContestTeaserWrapper>
  );
};

export default HolidayFinderContestTeaser;
