import last from 'lodash/last';
import type { TFunction } from 'next-i18next';
import Head from 'next/head';
import React from 'react';
import {
  AggregateRating,
  BreadcrumbList,
  Hotel,
  ListItem,
  LocalBusiness,
  OpeningHoursSpecification,
  Organization,
  WithContext,
} from 'schema-dts';
import type {
  Breadcrumb,
  SingleOrlItem,
  Shift,
  Organization as OrganizationType,
} from '@hotelplan/graphql.types';
import { TravelType } from '@hotelplan/graphql.types';
import { objectIdTypes, getPageById } from '@hotelplan/libs.context.object-id';
import { languageDefault } from '@hotelplan/libs.language';
import { capitalize, formatPrice, withoutTags } from '@hotelplan/libs.utils';
import { AgencyFragment } from 'graphql/agency/AgencyFragment.generated';
import { PdpOverviewFragment } from 'graphql/pdp/PDPOverview.generated';

interface IJsonLd {
  data?: object;
}

// @todo might be different, put to env?
const HOME_URL = 'https://www.hotelplan.ch';

export const StructuredData: React.FC<IJsonLd> = ({ data }) =>
  data ? (
    <Head>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
      />
    </Head>
  ) : null;

export const getPDPStructuredData = (
  t: TFunction,
  overview: PdpOverviewFragment,
  priceDateOverview: SingleOrlItem[],
  description: string | undefined,
  url: string,
  imageURL: string | undefined,
  address: string | undefined
): WithContext<Hotel> => {
  const getNightsText = (count: number): string => t('nights.count', { count });

  const isPackage = priceDateOverview[0]?.travelType === TravelType.Package;

  const nightsText = getNightsText(priceDateOverview[0]?.duration || 1);
  const priceText = priceDateOverview[0]?.pricePerPerson
    ? formatPrice(priceDateOverview[0].pricePerPerson)
    : '';
  const boardTypeText = capitalize(priceDateOverview[0]?.board || '');

  const priceRange = `${t('perPerson.from')} ${priceText} (${
    isPackage ? t('includes-flight') + ', ' : ''
  }${nightsText}${boardTypeText ? ', ' + boardTypeText : ''})`;

  const aggregateRating: AggregateRating | undefined = !overview.taRating // does not appear if hotel has no TA rating
    ? undefined
    : {
        '@type': 'AggregateRating',
        bestRating: 5,
        ratingValue: overview.taRating?.rating
          ? overview.taRating.rating / 10
          : undefined,
        reviewCount: overview.taRating.count ?? undefined,
      };

  return {
    '@context': 'https://schema.org',
    '@type': 'Hotel',
    name: overview.productName,
    description: withoutTags(description),
    url: HOME_URL + url,
    image: imageURL,
    aggregateRating,
    priceRange,
    address: {
      '@type': 'PostalAddress',
      addressCountry: address,
    },
  };
};

export const supportsStructuredDataForPDP = (objectId: string) => {
  const PAGES_WITHOUT_STRUCTURED_DATA = [
    objectIdTypes.o?.name,
    objectIdTypes.s?.name,
  ];

  return !PAGES_WITHOUT_STRUCTURED_DATA.includes(
    getPageById(objectId)?.name || ''
  );
};

export const getGeoStructuredData = (
  breadCrumbItems: Breadcrumb[] | undefined,
  language?: string
): WithContext<BreadcrumbList> => {
  const rootJsonBreadCrumb: ListItem = {
    '@type': 'ListItem',
    position: 1,
    item: {
      '@type': 'WebPage',
      '@id':
        (HOME_URL as string) +
        (language === languageDefault ? '' : `/${language}/`),
      name: 'Home',
    },
  };

  const jsonLdBreadcrumbs: ListItem[] =
    breadCrumbItems?.map(({ caption, link: { uri } }, i) => ({
      '@type': 'ListItem',
      position: i + 2,
      item: {
        '@type': 'WebPage',
        '@id': HOME_URL + uri,
        name: caption,
      },
    })) || [];

  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [rootJsonBreadCrumb, ...jsonLdBreadcrumbs],
  };
};

export const getHomePageStructuredData = (
  data: OrganizationType | null
): WithContext<Organization> => {
  return data
    ? {
        '@context': 'https://schema.org',
        '@type': 'Organization',
        name: data.name,
        url: data.url,
        logo: data.logo,
        contactPoint: {
          '@type': 'ContactPoint',
          telephone: data.phone,
          contactType: `customer service`,
        },
        address: {
          '@type': 'PostalAddress',
          addressLocality: data.place,
          addressRegion: data.region,
          postalCode: data.postalCode,
          streetAddress: data.street,
        },
      }
    : null;
};

export function getAgencyPageStructuredData<A extends AgencyFragment>({
  addressDetails,
  coordinates,
  email,
  images,
  openingHours,
  phone,
  title,
  url,
}: A): WithContext<LocalBusiness> {
  const resizedImages = images.find(({ alt }) => alt === 'AGT_EXT')?.resized;
  const image = last(resizedImages)?.url;

  const schemaDayOfWeek = (
    index: number,
    shifts: Shift[]
  ): OpeningHoursSpecification[] => {
    /* eslint-disable i18next/no-literal-string */
    const daysOfWeek = [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ];
    /* eslint-enable i18next/no-literal-string */
    return shifts.map(({ from, to }) => ({
      '@type': 'OpeningHoursSpecification',
      opens: from,
      closes: to,
      dayOfWeek: ('https://schema.org/' + daysOfWeek[index]) as any,
    }));
  };

  const openingHoursAsSchema: OpeningHoursSpecification[] = openingHours.reduce<
    OpeningHoursSpecification[]
  >(
    (acc, { open, shifts }, index) =>
      open ? [...acc, ...schemaDayOfWeek(index, shifts)] : acc,
    []
  );

  return {
    '@context': 'https://schema.org',
    '@type': 'LocalBusiness',
    name: title,
    url: HOME_URL + url.uri,
    image,
    openingHoursSpecification: openingHoursAsSchema,
    telephone: phone.caption ?? undefined,
    email,
    address: {
      '@type': 'PostalAddress',
      addressLocality: addressDetails.city ?? undefined,
      addressRegion: addressDetails.region ?? undefined,
      postalCode: addressDetails.zip ?? undefined,
      streetAddress: addressDetails.street ?? undefined,
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: coordinates.latitude,
      longitude: coordinates.longitude,
    },
  };
}

export const mapURLDataToBreadcrumb = (
  uri: string,
  caption: string
): object => {
  return {
    caption,
    link: { uri },
  };
};
