import type { NextPage } from 'next';
import React from 'react';
import { PageType } from '@hotelplan/graphql.types';
import {
  mapGlobalSearchStateToFormState,
  mapGlobalSearchStateToSearchControlCriteriaInput,
} from '@hotelplan/libs.gss';
import { gssUnitRef } from '@hotelplan/libs.gss-api';
import { createOpenGraphProp } from '@hotelplan/libs.open-graph';
import { apolloReqCtxUnitRef } from '@hotelplan/libs.req-ctx-api';
import { ServerSideUnit, ServerSideUnitRef } from '@hotelplan/libs.ssp-units';
import {
  MoreVisibilityStaticComponent,
  LastSeenItem,
} from '@hotelplan/platform-graphql-api';
import HomeComponents from 'components/domain/home/HomeComponents';
import {
  getHomePageStructuredData,
  StructuredData,
} from 'components/domain/StructuredData';
import { lastSeenHotelsUnit } from 'config/all';
import { createPageServerFn } from 'config/pageUnitsConfig';
import {
  GetHomeMetaDataDocument,
  GetHomeMetaDataQuery,
  GetHomeMetaDataQueryVariables,
} from 'graphql/home/GetHomeMetaData.generated';
import {
  GetHomeSearchControlValuesDocument,
  GetHomeSearchControlValuesQuery,
  GetHomeSearchControlValuesQueryVariables,
} from 'graphql/home/GetHomeSearchControlValues.generated';
import {
  GetHomeStaticComponentsDocument,
  GetHomeStaticComponentsQuery,
  GetHomeStaticComponentsQueryVariables,
} from 'graphql/home/GetHomeStaticComponents.generated';
import {
  GetMoreVisibleComponentDocument,
  GetMoreVisibleComponentQuery,
  GetMoreVisibleComponentQueryVariables,
} from 'graphql/home/GetMoreVisibleComponent.generated';
import {
  GetOrganizationDocument,
  GetOrganizationQuery,
  GetOrganizationQueryVariables,
  useGetOrganizationQuery,
} from 'graphql/organization/GetOrganization.generated';

function OrganizationStructuredData() {
  const { data } = useGetOrganizationQuery({});
  return (
    <StructuredData
      data={getHomePageStructuredData(data?.organization || null)}
    />
  );
}

interface IHomePageProps {
  moreVisibleComponent: MoreVisibilityStaticComponent;
  lastSeenHotels?: LastSeenItem[];
}

const HomePage: NextPage<IHomePageProps> = ({
  moreVisibleComponent,
  lastSeenHotels,
}) => {
  return (
    <>
      <OrganizationStructuredData />
      <HomeComponents
        moreVisibleComponent={moreVisibleComponent}
        hasLastSeenHotels={!!lastSeenHotels?.length}
      />
    </>
  );
};

export const getServerSideProps = createPageServerFn(
  {
    pageType: PageType.Home,
    pageEventType: `home`,
    namespaceRequired: [`home`, `wishlist`, `results`, `holidayfinder`, `orl`],
  },
  [
    lastSeenHotelsUnit,
    ServerSideUnit.createServerSideUnit(
      [gssUnitRef, apolloReqCtxUnitRef],
      async (
        // deps
        ctx,
        gssPayload,
        { queryCtx, writeQuery }
      ) => {
        const gss = mapGlobalSearchStateToFormState(gssPayload.gss);

        const [
          { data: dataMeta },
          { data: searchDefaultData },
          { data: staticData },
          { data: moreVisibleComponentData },
        ] = await Promise.all([
          queryCtx<GetHomeMetaDataQuery, GetHomeMetaDataQueryVariables>({
            query: GetHomeMetaDataDocument,
          }),
          queryCtx<
            GetHomeSearchControlValuesQuery,
            GetHomeSearchControlValuesQueryVariables
          >({
            query: GetHomeSearchControlValuesDocument,
            variables: {}, // no travel type, for default data
          }),
          queryCtx<
            GetHomeStaticComponentsQuery,
            GetHomeStaticComponentsQueryVariables
          >({
            query: GetHomeStaticComponentsDocument,
          }),
          queryCtx<
            GetMoreVisibleComponentQuery,
            GetMoreVisibleComponentQueryVariables
          >({
            query: GetMoreVisibleComponentDocument,
            variables: {
              gss: mapGlobalSearchStateToSearchControlCriteriaInput(gss),
            },
          }),
          queryCtx<GetOrganizationQuery, GetOrganizationQueryVariables>({
            query: GetOrganizationDocument,
          }),
          queryCtx<
            GetHomeSearchControlValuesQuery,
            GetHomeSearchControlValuesQueryVariables
          >({
            query: GetHomeSearchControlValuesDocument,
            variables: {
              travelType: gss?.travelType,
            },
          }),
        ] as const);

        const defaultTravelType =
          searchDefaultData.home.searchControl.travelType;

        // Save search controls default data to cache
        // with travel type that comes with it
        writeQuery<
          GetHomeSearchControlValuesQuery,
          GetHomeSearchControlValuesQueryVariables
        >({
          query: GetHomeSearchControlValuesDocument,
          variables: { travelType: defaultTravelType },
          data: searchDefaultData,
        });

        return {
          props: {
            meta: dataMeta.home.meta,
            ...createOpenGraphProp({
              image: staticData.home.staticContent.hero?.resized?.[0]?.url,
            }),
            moreVisibleComponent:
              moreVisibleComponentData?.home.moreVisibleComponent,
          },
        };
      },
      new ServerSideUnitRef<any>(`home-preload`)
    ),
  ] as any
);

export default HomePage;
