import React from 'react';
import styled from 'styled-components';
import { LoginErrorNotification } from '@hotelplan/components.common.auth';
import { Hero, HeroSkeleton } from '@hotelplan/components.common.hero';
import { useDeviceType } from '@hotelplan/libs.context.device-type';
import { useFeatureToggle } from '@hotelplan/libs.feature-toggle';
import { trackSearch } from '@hotelplan/libs.tracking';
import { MoreVisibilityStaticComponent } from '@hotelplan/platform-graphql-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import Container from 'components/domain/container/Container';
import { DynamicComponents } from 'components/domain/dynamic-components';
import HolidayFinderContestTeaser from 'components/domain/holiday-finder/HolidayFinderContestTeaser';
import { mapSearchControlStateToTrackableData } from 'components/domain/searchControl/SearchControl.mappers';
import StyledTextBlock from 'components/domain/StyledTextBlock';
import { FeatureList } from 'config/pageConfig.constants';
import { useGetHomeStaticComponentsQuery } from 'graphql/home/GetHomeStaticComponents.generated';
import HomeDynamicComponentsSkeleton from './HomeDynamicComponents.skeleton';
import HomeSearchForm from './HomeSearchForm';
import MoreVisibleBd4Recommendations from './MoreVisibleBD4Recommendations';
import MoreVisibleComponent from './MoreVisibleComponent';

const HomeComponentWrap = styled.div<{
  offersOnTop: boolean;
  hasLastSeenHotels: boolean;
}>(({ theme: { space, radii }, offersOnTop, hasLastSeenHotels }) =>
  sx2CssThemeFn({
    paddingBottom: [0, hasLastSeenHotels ? '60px' : '100px'],
    marginTop: [0, offersOnTop ? '34px' : '50px'],
    zIndex: 1,
    ...(offersOnTop && {
      '.dynamic-components': {
        borderRadius: radii.roundedTop,
      },
      '.contest-teaser-content': {
        borderTop: ['none', '1px solid'],
        borderColor: [null, 'lightGreyFirst'],
      },
    }),
    '.dynamic-components > *:first-child': {
      marginTop: space[3],
      '.group-heading': {
        borderTop: 'none',
      },
    },
  })
);

const BannerFormWrap = styled.div<{ backgroundColor?: string }>(
  ({ backgroundColor }) =>
    sx2CssThemeFn({
      pt: [0, '120px'],
      position: [null, 'relative'],
      display: [null, 'flex'],
      flexDirection: [null, 'column'],
      '.tabControls': {
        height: ['50px', '58px'],
      },
      '.hero-content': {
        top: '206px',
        py: 0,
        display: ['none', 'block'],
      },
      backgroundColor: [
        null,
        backgroundColor ? backgroundColor : 'bgLightBlue',
      ],
      '@media screen and (max-width: 891px)': { backgroundColor: 'initial' },
    })
);

const HomeStaticSection = styled(Container.withComponent(`section`))(
  sx2CssThemeFn({
    paddingTop: [4, `50px`],
    '.home-static-text': {
      pb: ['40px', '34px'],
      px: [4, 0],
    },
  })
);

const HolidayFinderContestTeaserContainer = styled.div(
  sx2CssThemeFn({
    px: 4,
    display: 'flex',
    justifyContent: 'center',
    background: 'none',
  })
);

const HeroContentInner = styled.div(
  sx2CssThemeFn({
    width: '100%',
    position: 'relative',
    maxWidth: '1170px',
    left: '50%',
    transform: 'translateX(-50%)',
  })
);

const HeroTitle = styled.h1(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    maxWidth: '357px',
    ...FONT_SIZE.XL,
  })
);

const HP_HOME_OFFERS_TO_TOP_VARIANT = 'top';

interface IHomeComponentsProps {
  moreVisibleComponent: MoreVisibilityStaticComponent;
  hasLastSeenHotels: boolean;
}

const HomeComponents: React.FC<IHomeComponentsProps> = ({
  moreVisibleComponent,
  hasLastSeenHotels,
}) => {
  const {
    data: homeStaticComponents,
    loading,
  } = useGetHomeStaticComponentsQuery();

  const { moreVisibleItems, bd4TravelRecommendation } = moreVisibleComponent;

  const { isEnabled, getVariant } = useFeatureToggle();
  const { mobile } = useDeviceType();

  const isContestTeaserEnabled = isEnabled(
    FeatureList.HOLIDAY_FINDER_CONTEST_TEASER
  );
  const hpHomeOffersToTopVariant = getVariant(
    FeatureList.HP_HOME_OFFERS_TO_TOP
  );
  const offersOnTop =
    hpHomeOffersToTopVariant?.enabled &&
    hpHomeOffersToTopVariant?.name == HP_HOME_OFFERS_TO_TOP_VARIANT;

  const staticContent = homeStaticComponents?.home?.staticContent;

  return (
    <>
      <BannerFormWrap backgroundColor={staticContent?.barColor}>
        {loading ? (
          <HeroSkeleton />
        ) : (
          <Hero
            macroImage={staticContent?.macroPicture}
            image={staticContent?.hero}
          >
            <HeroContentInner>
              {staticContent?.info?.title && (
                <HeroTitle>{staticContent.info.title}</HeroTitle>
              )}
            </HeroContentInner>
          </Hero>
        )}
        <HomeSearchForm
          postSubmit={state =>
            trackSearch({
              searchControl: mapSearchControlStateToTrackableData(state),
            })
          }
          hasMoreVisibleItems={!!moreVisibleItems.length}
        />
      </BannerFormWrap>
      {moreVisibleItems.length ? (
        <MoreVisibleComponent moreVisibleItems={moreVisibleItems} />
      ) : null}
      {bd4TravelRecommendation?.items.length ? (
        <MoreVisibleBd4Recommendations
          bd4TravelRecommendation={bd4TravelRecommendation}
        />
      ) : null}
      <HomeComponentWrap
        offersOnTop={offersOnTop}
        hasLastSeenHotels={hasLastSeenHotels}
      >
        {offersOnTop && (
          <DynamicComponents
            className={'dynamic-components'}
            placeholder={<HomeDynamicComponentsSkeleton />}
          />
        )}
        {isContestTeaserEnabled && !offersOnTop && !mobile ? (
          <HolidayFinderContestTeaserContainer>
            <HolidayFinderContestTeaser />
          </HolidayFinderContestTeaserContainer>
        ) : null}
        <HomeStaticSection>
          {homeStaticComponents && (
            <StyledTextBlock
              title={mobile && staticContent?.info?.title}
              text={staticContent?.info?.text}
              backgroundImage={staticContent?.info?.backgroundImage}
              backgroundColor={staticContent?.info?.backgroundColor}
              fontColor={staticContent?.info?.fontColor}
              className="home-static-text"
            />
          )}
          {isContestTeaserEnabled && (offersOnTop || mobile) ? (
            <HolidayFinderContestTeaserContainer>
              <HolidayFinderContestTeaser />
            </HolidayFinderContestTeaserContainer>
          ) : null}
        </HomeStaticSection>
        {!offersOnTop && (
          <DynamicComponents
            className={'dynamic-components'}
            placeholder={<HomeDynamicComponentsSkeleton />}
          />
        )}
        <LoginErrorNotification />
      </HomeComponentWrap>
    </>
  );
};

export default HomeComponents;
