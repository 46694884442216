import type { TFunction } from 'next-i18next';
import {
  getShortInformation,
  mapExactTravelPeriodToFormTravelDates,
  mapFlexibleTravelPeriodToFormTravelDate,
} from '@hotelplan/components.common.travel-dates';
import {
  ExactTravelPeriod,
  FlexibleTravelPeriod,
  GeoObject,
  MoreVisibleItem,
  MoreVisibleItemType,
} from '@hotelplan/platform-graphql-api';

export const getTravelInformation = (
  t: TFunction,
  travelPeriod: ExactTravelPeriod | FlexibleTravelPeriod
): string => {
  const isExactTravelPeriod = travelPeriod.__typename === 'ExactTravelPeriod';

  const travelDates = isExactTravelPeriod
    ? mapExactTravelPeriodToFormTravelDates(travelPeriod)
    : mapFlexibleTravelPeriodToFormTravelDate(travelPeriod);

  const travelInfo = getShortInformation(t, {
    returnDate: travelDates.returnDate,
    departureDate: travelDates.departureDate,
    durationData: travelDates.duration,
  });

  return `${travelInfo.travelDates} | ${travelInfo.duration}`;
};

export const getMoreVisibilityLocation = (
  t: TFunction,
  locations: GeoObject[]
) => {
  return locations.length
    ? locations.map(location => location.name).join(' / ')
    : t('common:allDestinations');
};

export const getMoreVisibilityComponentItemTitleArray = (
  t: TFunction,
  moreVisibleItems: MoreVisibleItem[]
) => {
  let searchesCount = 0;
  let offersCount = 0;
  let wishlistCount = 0;

  return moreVisibleItems.map(item => {
    if (
      item.itemType === MoreVisibleItemType.MoreVisibleFromWishlist &&
      wishlistCount === 0
    ) {
      wishlistCount = 1;
      return t('home:moreVisibleOffers.itemType.wishlist');
    }

    if (
      item.itemType === MoreVisibleItemType.MoreVisibleFromOffers &&
      offersCount === 0
    ) {
      offersCount = 1;
      return moreVisibleItems[1]?.itemType ===
        MoreVisibleItemType.MoreVisibleFromOffers &&
        moreVisibleItems[2]?.itemType ===
          MoreVisibleItemType.MoreVisibleFromOffers
        ? t('home:moreVisibleOffers.itemType.offers.plural')
        : t('home:moreVisibleOffers.itemType.offers');
    }

    if (
      item.itemType === MoreVisibleItemType.MoreVisibleFromSearches &&
      searchesCount === 0
    ) {
      searchesCount = 1;
      return t('home:moreVisibleOffers.itemType.searches');
    }

    return '';
  });
};
