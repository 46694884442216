import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { ResizedImageWithMetaFragmentDoc } from 'graphql/image/ResizedImageWithMeta.generated';
import { TextBlockInfoStylesFragmentDoc } from 'graphql/fragments/TextBlockInfoStyles.generated';
import { TravelPeriodFragmentDoc } from 'graphql/searchControl/TravelPeriod.generated';
export type HolidayFinderLandingPageFragment = {
  __typename?: 'HolidayFinderLandingPage';
  children: boolean;
  hero: {
    __typename?: 'Image';
    alt: string;
    desaturated?: boolean | null;
    resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
  };
  info: {
    __typename?: 'TextComponent';
    text: string;
    title: string;
    fontColor?: string | null;
    backgroundColor?: string | null;
    backgroundImage?: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
  };
  travelPeriod: {
    __typename?: 'TravelPeriodComponent';
    type: Types.SearchPeriodType;
    exactPeriod: {
      __typename?: 'ExactTravelPeriod';
      departureDate: string;
      returnDate: string;
    };
    flexiblePeriod: {
      __typename?: 'FlexibleTravelPeriod';
      departureDate: string;
      maxDuration: number;
      minDuration: number;
      returnDate: string;
    };
  };
};

export const HolidayFinderLandingPageFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'holidayFinderLandingPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HolidayFinderLandingPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'children' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'hero' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'criteria' },
                value: {
                  kind: 'ListValue',
                  values: [
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1920' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '800' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_desktop',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '3840' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '1600' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '1x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '800' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '320' },
                        },
                      ],
                    },
                    {
                      kind: 'ObjectValue',
                      fields: [
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'id' },
                          value: {
                            kind: 'StringValue',
                            value: '2x_mobile',
                            block: false,
                          },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'width' },
                          value: { kind: 'IntValue', value: '1600' },
                        },
                        {
                          kind: 'ObjectField',
                          name: { kind: 'Name', value: 'height' },
                          value: { kind: 'IntValue', value: '640' },
                        },
                      ],
                    },
                  ],
                },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'ResizedImageWithMeta' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'info' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'textBlockInfoStyles' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'travelPeriod' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'TravelPeriod' },
                },
              ],
            },
          },
        ],
      },
    },
    ...ResizedImageWithMetaFragmentDoc.definitions,
    ...TextBlockInfoStylesFragmentDoc.definitions,
    ...TravelPeriodFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
