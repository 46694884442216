import React from 'react';
import styled from 'styled-components';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  DynamicComponentStyles,
  DynamicComponentWrapper,
} from 'components/domain/dynamic-components/DynamicComponents.styles';
import {
  getStyle,
  StyleSettingsDynamicComponents,
} from 'components/domain/dynamic-components/DynamicComponents.utils';
import RecommendationGroup from 'components/domain/recommendationGroup/RecommendationGroup';
import { Bd4TravelRecommendationGroupFragment } from 'graphql/dynamic-components/GetDynamicComponents.generated';

const BD4TravelRecommendationWrapper = styled(DynamicComponentStyles)(
  sx2CssThemeFn({
    '.group-heading': {
      pt: 0,
      border: 'none',
    },
    '.more-visible-recommender-wrap': {
      mt: [4, 5],
    },
    '.group-wrapper': {
      pt: ['40px', '50px'],
      pb: 0,
    },
  })
);

interface IMoreVisibleBd4RecommendationsProps {
  bd4TravelRecommendation: Bd4TravelRecommendationGroupFragment;
}

const MoreVisibleBd4Recommendations: React.FC<IMoreVisibleBd4RecommendationsProps> = ({
  bd4TravelRecommendation,
}) => {
  return (
    <BD4TravelRecommendationWrapper
      backgroundColor={getStyle(
        bd4TravelRecommendation,
        StyleSettingsDynamicComponents.BACKGROUND_COLOR
      )}
    >
      <DynamicComponentWrapper withoutContainer={false}>
        <RecommendationGroup
          className="more-visible-recommender-wrap"
          fontColor={bd4TravelRecommendation.fontColor}
          name={bd4TravelRecommendation.name}
          mainText={bd4TravelRecommendation.mainText}
          items={bd4TravelRecommendation.items}
          tracking={
            'tracking' in bd4TravelRecommendation
              ? bd4TravelRecommendation.tracking
              : undefined
          }
        />
      </DynamicComponentWrapper>
    </BD4TravelRecommendationWrapper>
  );
};

export default MoreVisibleBd4Recommendations;
