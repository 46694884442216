import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { MoreVisibleItem } from '@hotelplan/platform-graphql-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import { ContainerStyles } from 'components/domain/container';
import MoreVisibleComponentItem from 'components/domain/home/MoreVisibleComponentItem';
import { getMoreVisibilityComponentItemTitleArray } from './MoreVisibleComponent.utils';

const MoreVisibleComponentWrap = styled.div(
  sx2CssThemeFn({
    backgroundColor: `lightGreySecond`,
    py: 6,
    px: [3, 0],
  })
);

const MoreVisibleComponentContainer = styled.ul(
  sx2CssThemeFn({
    ...ContainerStyles,
    display: 'flex',
    gap: 3,
    justifyContent: 'space-between',
    flexDirection: ['column', 'row'],
  })
);

interface IMoreVisibleComponentProps {
  moreVisibleItems: MoreVisibleItem[];
}

const MoreVisibleComponent: React.FC<IMoreVisibleComponentProps> = ({
  moreVisibleItems,
}) => {
  const [t] = useTranslation(['home', 'results']);

  const titlesArray: string[] = useMemo(
    () => getMoreVisibilityComponentItemTitleArray(t, moreVisibleItems),
    [moreVisibleItems]
  );

  return (
    <MoreVisibleComponentWrap>
      <MoreVisibleComponentContainer>
        {moreVisibleItems.map((moreVisibleItem, index) => {
          return (
            <MoreVisibleComponentItem
              moreVisibleItem={moreVisibleItem}
              key={index}
              title={titlesArray[index]}
            />
          );
        })}
      </MoreVisibleComponentContainer>
    </MoreVisibleComponentWrap>
  );
};

export default MoreVisibleComponent;
