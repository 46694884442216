import { useTranslation } from 'next-i18next';
import React from 'react';
import styled from 'styled-components';
import { useAuthentication } from '@hotelplan/components.common.auth';
import { useFormContext } from '@hotelplan/components.common.forms';
import { Icon } from '@hotelplan/components.common.icon';
import { BsButton } from '@hotelplan/core.basis.bs-button';
import { AuthChannelType } from '@hotelplan/graphql.local-types';
import { E_BUTTON_TYPE } from '@hotelplan/style.hotelplan-style';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';

const ResetButton = styled(BsButton)(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.BUTTON,
    py: 2,
  })
);

const ResetIcon = styled(Icon)(
  sx2CssThemeFn({
    mr: 2,
    width: '25px',
    height: '25px',
  })
);

const SearchControlFormResetButton: React.FC = () => {
  const [t] = useTranslation(['common']);

  const { onReset } = useFormContext();
  const { channelType } = useAuthentication();

  if (channelType !== AuthChannelType.B2B) return null;

  return (
    <ResetButton
      onClick={onReset}
      variant={E_BUTTON_TYPE.LINK_BUTTON}
      data-id="reset-search-control-form-btn"
    >
      <ResetIcon name="app-window-refresh" />
      {t('resetSearchControlForm.button')}
    </ResetButton>
  );
};

export default SearchControlFormResetButton;
