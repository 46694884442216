import type * as Types from 'types/types';

import type { DocumentNode } from 'graphql';
import { TextBlockInfoStylesFragmentDoc } from 'graphql/fragments/TextBlockInfoStyles.generated';
export type HolidayFinderPageFragment = {
  __typename?: 'HolidayFinderPage';
  info: {
    __typename?: 'TextComponent';
    text: string;
    title: string;
    fontColor?: string | null;
    backgroundColor?: string | null;
    backgroundImage?: {
      __typename?: 'Image';
      alt: string;
      desaturated?: boolean | null;
      resized: Array<{ __typename?: 'ResizedImage'; url: string; id: string }>;
    } | null;
  };
};

export const HolidayFinderPageFragmentDoc = ({
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'holidayFinderPage' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'HolidayFinderPage' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'info' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'text' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'FragmentSpread',
                  name: { kind: 'Name', value: 'textBlockInfoStyles' },
                },
              ],
            },
          },
        ],
      },
    },
    ...TextBlockInfoStylesFragmentDoc.definitions,
  ],
} as unknown) as DocumentNode;
