import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Image } from '@hotelplan/components.common.image';
import { Link } from '@hotelplan/components.common.link';
import { RouterLink } from '@hotelplan/components.common.router-link';
import { getShortInformation as getTravellersShortInformation } from '@hotelplan/components.common.travel-rooms';
import { getTargetPageData } from '@hotelplan/libs.router-link-utils';
import { MoreVisibleItem } from '@hotelplan/platform-graphql-api';
import { sx2CssThemeFn } from '@hotelplan/util.theme.sxc';
import {
  getMoreVisibilityLocation,
  getTravelInformation,
} from 'components/domain/home/MoreVisibleComponent.utils';
import { mapTravelTypeToTravelTypeData } from 'components/domain/travelTypes/TravelTypes.mappers';

const MoreVisibleComponentWrap = styled.li(
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'column',
    width: ['100%', '33%'],
    ':first-child h3': {
      pt: 0,
    },
  })
);

const ItemTitle = styled.h3(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.S,
    mb: [3, 0],
    pt: [4, 0],
    position: ['initial', 'absolute'],
  })
);

const ItemCard = styled(Link)(({ theme: { colors } }) =>
  sx2CssThemeFn({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: colors.white,
    borderRadius: '5px',
    height: '100%',
    mt: [0, '50px'],
    cursor: 'pointer',
    ':hover': {
      color: 'inherit',
    },
  })
);

const ItemImage = styled(Image)(
  sx2CssThemeFn({
    width: '76px',
    overflow: 'hidden',
    objectFit: 'cover',
    borderRadius: '4px',
    flexShrink: '0',
    img: {
      height: '100%',
      width: 'auto',
      maxWidth: 'fit-content',
    },
  })
);

const ItemContent = styled.div(({ theme: { FONT_SIZE } }) =>
  sx2CssThemeFn({
    ...FONT_SIZE.SMALL,
    padding: 3,
    color: 'darkGray',
    overflow: 'hidden',
    '.title': {
      ...FONT_SIZE.LARGE_BOLD,
      width: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: 'black',
    },
  })
);

interface IMoreVisibleComponentItemProps {
  title: string;
  moreVisibleItem: MoreVisibleItem;
}

const MoreVisibleComponentItem: FC<IMoreVisibleComponentItemProps> = ({
  title,
  moreVisibleItem,
}) => {
  const {
    travellers,
    travelPeriod,
    travelType,
    rooms,
    moreVisibleItemImage,
    locations,
    actionLink,
  } = moreVisibleItem;
  const [t] = useTranslation(['home', 'results']);

  const travellersInfo = getTravellersShortInformation(t, {
    rooms,
    adults: travellers.adults,
    childrenDobs: travellers.childrenDobs || [],
  });
  const travelInfo = getTravelInformation(t, travelPeriod);

  return (
    <MoreVisibleComponentWrap>
      <>
        {title ? <ItemTitle>{title}</ItemTitle> : null}
        <RouterLink {...getTargetPageData(actionLink)} passHref>
          <ItemCard>
            <ItemImage {...moreVisibleItemImage} />
            <ItemContent>
              <p className="title">{getMoreVisibilityLocation(t, locations)}</p>
              <p>
                {t(mapTravelTypeToTravelTypeData(travelType).label)}
                {', '}
                {travellersInfo}
              </p>
              <p>{travelInfo}</p>
            </ItemContent>
          </ItemCard>
        </RouterLink>
      </>
    </MoreVisibleComponentWrap>
  );
};

export default MoreVisibleComponentItem;
